import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _90156fb0 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _44a510ab = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _89436aba = () => interopDefault(import('../pages/authors.vue' /* webpackChunkName: "pages/authors" */))
const _392fc7ec = () => interopDefault(import('../pages/cgu.vue' /* webpackChunkName: "pages/cgu" */))
const _bd5673f8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _154f8ba2 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _98582518 = () => interopDefault(import('../pages/embed.vue' /* webpackChunkName: "pages/embed" */))
const _6525f01e = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _06806458 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _589648db = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _65c1393a = () => interopDefault(import('../pages/register/1.vue' /* webpackChunkName: "pages/register/1" */))
const _65cf50bb = () => interopDefault(import('../pages/register/2.vue' /* webpackChunkName: "pages/register/2" */))
const _65dd683c = () => interopDefault(import('../pages/register/3.vue' /* webpackChunkName: "pages/register/3" */))
const _65eb7fbd = () => interopDefault(import('../pages/register/4.vue' /* webpackChunkName: "pages/register/4" */))
const _65f9973e = () => interopDefault(import('../pages/register/5.vue' /* webpackChunkName: "pages/register/5" */))
const _fe7b41be = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _e3d19760 = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _ff85e524 = () => interopDefault(import('../pages/posts.vue' /* webpackChunkName: "pages/posts" */))
const _1e394971 = () => interopDefault(import('../pages/posts/index.vue' /* webpackChunkName: "pages/posts/index" */))
const _49037848 = () => interopDefault(import('../pages/posts/_id/index.vue' /* webpackChunkName: "pages/posts/_id/index" */))
const _fa262bd2 = () => interopDefault(import('../pages/posts/_id/_slug/index.vue' /* webpackChunkName: "pages/posts/_id/_slug/index" */))
const _cb2fa8f8 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _32deff07 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _199c87a2 = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _093eb104 = () => interopDefault(import('../pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _dbf01b7e = () => interopDefault(import('../pages/publication-terms.vue' /* webpackChunkName: "pages/publication-terms" */))
const _37b850e0 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _2f8909d2 = () => interopDefault(import('../pages/resubscribe.vue' /* webpackChunkName: "pages/resubscribe" */))
const _854edd6a = () => interopDefault(import('../pages/studio.vue' /* webpackChunkName: "pages/studio" */))
const _2fd0b9fb = () => interopDefault(import('../pages/why-kessel.vue' /* webpackChunkName: "pages/why-kessel" */))
const _2e878279 = () => interopDefault(import('../pages/unsubscribe/email.vue' /* webpackChunkName: "pages/unsubscribe/email" */))
const _6ab9fdb4 = () => interopDefault(import('../pages/publication/create.vue' /* webpackChunkName: "pages/publication/create" */))
const _eb932592 = () => interopDefault(import('../pages/publication/create/index.vue' /* webpackChunkName: "pages/publication/create/index" */))
const _bc0c28d4 = () => interopDefault(import('../pages/publication/create/1.vue' /* webpackChunkName: "pages/publication/create/1" */))
const _bbeff9d2 = () => interopDefault(import('../pages/publication/create/2.vue' /* webpackChunkName: "pages/publication/create/2" */))
const _580f7fa4 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _747a27c7 = () => interopDefault(import('../pages/category/_slug/_tab.vue' /* webpackChunkName: "pages/category/_slug/_tab" */))
const _279fb99c = () => interopDefault(import('../pages/publication/_id.vue' /* webpackChunkName: "pages/publication/_id" */))
const _29e40796 = () => interopDefault(import('../pages/publication/_id/index.vue' /* webpackChunkName: "pages/publication/_id/index" */))
const _0721f7c2 = () => interopDefault(import('../pages/publication/_id/drafts.vue' /* webpackChunkName: "pages/publication/_id/drafts" */))
const _d8c43294 = () => interopDefault(import('../pages/publication/_id/posts.vue' /* webpackChunkName: "pages/publication/_id/posts" */))
const _7e937c59 = () => interopDefault(import('../pages/publication/_id/referrals.vue' /* webpackChunkName: "pages/publication/_id/referrals" */))
const _c0ed18e0 = () => interopDefault(import('../pages/publication/_id/settings.vue' /* webpackChunkName: "pages/publication/_id/settings" */))
const _56b50e13 = () => interopDefault(import('../pages/publication/_id/settings/index.vue' /* webpackChunkName: "pages/publication/_id/settings/index" */))
const _73feb922 = () => interopDefault(import('../pages/publication/_id/settings/contacts.vue' /* webpackChunkName: "pages/publication/_id/settings/contacts" */))
const _0a05682b = () => interopDefault(import('../pages/publication/_id/settings/customise.vue' /* webpackChunkName: "pages/publication/_id/settings/customise" */))
const _5442eecd = () => interopDefault(import('../pages/publication/_id/settings/design.vue' /* webpackChunkName: "pages/publication/_id/settings/design" */))
const _569d5598 = () => interopDefault(import('../pages/publication/_id/settings/import.vue' /* webpackChunkName: "pages/publication/_id/settings/import" */))
const _58385f9f = () => interopDefault(import('../pages/publication/_id/settings/options.vue' /* webpackChunkName: "pages/publication/_id/settings/options" */))
const _7025f47d = () => interopDefault(import('../pages/publication/_id/settings/recommend.vue' /* webpackChunkName: "pages/publication/_id/settings/recommend" */))
const _ba65c128 = () => interopDefault(import('../pages/publication/_id/settings/referral.vue' /* webpackChunkName: "pages/publication/_id/settings/referral" */))
const _6041896c = () => interopDefault(import('../pages/publication/_id/settings/subscription.vue' /* webpackChunkName: "pages/publication/_id/settings/subscription" */))
const _c0be2cfc = () => interopDefault(import('../pages/publication/_id/stats.vue' /* webpackChunkName: "pages/publication/_id/stats" */))
const _8bbbe8a4 = () => interopDefault(import('../pages/publication/_id/subscribers.vue' /* webpackChunkName: "pages/publication/_id/subscribers" */))
const _7146467c = () => interopDefault(import('../pages/publication/_id/write.vue' /* webpackChunkName: "pages/publication/_id/write" */))
const _9b96233e = () => interopDefault(import('../pages/publication/_id/templates/_templateId.vue' /* webpackChunkName: "pages/publication/_id/templates/_templateId" */))
const _333e7138 = () => interopDefault(import('../pages/search/_tab.vue' /* webpackChunkName: "pages/search/_tab" */))
const _dd4ee71a = () => interopDefault(import('../pages/search/_tab/_slug.vue' /* webpackChunkName: "pages/search/_tab/_slug" */))
const _50a5ba26 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _90156fb0,
    children: [{
      path: "",
      component: _44a510ab,
      name: "about___fr"
    }]
  }, {
    path: "/auteurs",
    component: _89436aba,
    name: "authors___fr"
  }, {
    path: "/cgu",
    component: _392fc7ec,
    name: "cgu___fr"
  }, {
    path: "/connexion",
    component: _bd5673f8,
    name: "login___fr"
  }, {
    path: "/dashboard",
    component: _154f8ba2,
    name: "dashboard___fr"
  }, {
    path: "/embed",
    component: _98582518,
    name: "embed___fr"
  }, {
    path: "/faq",
    component: _6525f01e,
    name: "faq___fr"
  }, {
    path: "/inscription",
    component: _06806458,
    children: [{
      path: "",
      component: _589648db,
      name: "register___fr"
    }, {
      path: "1",
      component: _65c1393a,
      name: "register-1___fr"
    }, {
      path: "2",
      component: _65cf50bb,
      name: "register-2___fr"
    }, {
      path: "3",
      component: _65dd683c,
      name: "register-3___fr"
    }, {
      path: "4",
      component: _65eb7fbd,
      name: "register-4___fr"
    }, {
      path: "5",
      component: _65f9973e,
      name: "register-5___fr"
    }]
  }, {
    path: "/legal-notice",
    component: _fe7b41be,
    name: "legal-notice___fr"
  }, {
    path: "/password",
    component: _e3d19760,
    name: "password___fr"
  }, {
    path: "/posts",
    component: _ff85e524,
    children: [{
      path: "",
      component: _1e394971,
      name: "posts___fr"
    }, {
      path: ":id",
      component: _49037848,
      name: "posts-id___fr"
    }, {
      path: ":id/:slug",
      component: _fa262bd2,
      name: "posts-id-slug___fr"
    }]
  }, {
    path: "/profile",
    component: _cb2fa8f8,
    children: [{
      path: "",
      component: _32deff07,
      name: "profile___fr"
    }, {
      path: "account",
      component: _199c87a2,
      name: "profile-account___fr"
    }, {
      path: "settings",
      component: _093eb104,
      name: "profile-settings___fr"
    }]
  }, {
    path: "/publication-terms",
    component: _dbf01b7e,
    name: "publication-terms___fr"
  }, {
    path: "/recherche",
    component: _37b850e0,
    name: "search___fr"
  }, {
    path: "/reinscription",
    component: _2f8909d2,
    name: "resubscribe___fr"
  }, {
    path: "/studio",
    component: _854edd6a,
    name: "studio___fr"
  }, {
    path: "/why-kessel",
    component: _2fd0b9fb,
    name: "why-kessel___fr"
  }, {
    path: "/desinscription/email",
    component: _2e878279,
    name: "unsubscribe-email___fr"
  }, {
    path: "/publication/create",
    component: _6ab9fdb4,
    children: [{
      path: "",
      component: _eb932592,
      name: "publication-create___fr"
    }, {
      path: "1",
      component: _bc0c28d4,
      name: "publication-create-1___fr"
    }, {
      path: "2",
      component: _bbeff9d2,
      name: "publication-create-2___fr"
    }]
  }, {
    path: "/categorie/:slug",
    component: _580f7fa4,
    name: "category-slug___fr",
    children: [{
      path: ":tab?",
      component: _747a27c7,
      name: "category-slug-tab___fr"
    }]
  }, {
    path: "/publication/:id?",
    component: _279fb99c,
    children: [{
      path: "",
      component: _29e40796,
      name: "publication-id___fr"
    }, {
      path: "drafts",
      component: _0721f7c2,
      name: "publication-id-drafts___fr"
    }, {
      path: "posts",
      component: _d8c43294,
      name: "publication-id-posts___fr"
    }, {
      path: "referrals",
      component: _7e937c59,
      name: "publication-id-referrals___fr"
    }, {
      path: "settings",
      component: _c0ed18e0,
      children: [{
        path: "",
        component: _56b50e13,
        name: "publication-id-settings___fr"
      }, {
        path: "contacts",
        component: _73feb922,
        name: "publication-id-settings-contacts___fr"
      }, {
        path: "customise",
        component: _0a05682b,
        name: "publication-id-settings-customise___fr"
      }, {
        path: "design",
        component: _5442eecd,
        name: "publication-id-settings-design___fr"
      }, {
        path: "import",
        component: _569d5598,
        name: "publication-id-settings-import___fr"
      }, {
        path: "options",
        component: _58385f9f,
        name: "publication-id-settings-options___fr"
      }, {
        path: "recommend",
        component: _7025f47d,
        name: "publication-id-settings-recommend___fr"
      }, {
        path: "referral",
        component: _ba65c128,
        name: "publication-id-settings-referral___fr"
      }, {
        path: "subscription",
        component: _6041896c,
        name: "publication-id-settings-subscription___fr"
      }]
    }, {
      path: "stats",
      component: _c0be2cfc,
      name: "publication-id-stats___fr"
    }, {
      path: "subscribers",
      component: _8bbbe8a4,
      name: "publication-id-subscribers___fr"
    }, {
      path: "write",
      component: _7146467c,
      name: "publication-id-write___fr"
    }, {
      path: "templates/:templateId?",
      component: _9b96233e,
      name: "publication-id-templates-templateId___fr"
    }]
  }, {
    path: "/recherche/:tab",
    component: _333e7138,
    name: "search-tab___fr",
    children: [{
      path: ":slug?",
      component: _dd4ee71a,
      name: "search-tab-slug___fr"
    }]
  }, {
    path: "/",
    component: _50a5ba26,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
