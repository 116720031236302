import { render, staticRenderFns } from "./Navbar.vue?vue&type=template&id=0a55844c&"
import script from "./Navbar.vue?vue&type=script&lang=js&"
export * from "./Navbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KImage: require('/tmp/build_acca07ff/apps/web/components/image/KImage.vue').default})
