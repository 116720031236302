import { render, staticRenderFns } from "./ConfigButtonForm.vue?vue&type=template&id=23bc648d&"
import script from "./ConfigButtonForm.vue?vue&type=script&lang=ts&"
export * from "./ConfigButtonForm.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigButtonForm.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KField: require('/tmp/build_acca07ff/apps/web/components/form/KField.vue').default,ColorPicker: require('/tmp/build_acca07ff/apps/web/components/input/ColorPicker.vue').default,KButton: require('/tmp/build_acca07ff/apps/web/components/buttons/KButton.vue').default})
