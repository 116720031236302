import { render, staticRenderFns } from "./TipTapButton.vue?vue&type=template&id=6c117edc&"
import script from "./TipTapButton.vue?vue&type=script&lang=ts&"
export * from "./TipTapButton.vue?vue&type=script&lang=ts&"
import style0 from "./TipTapButton.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/tmp/build_acca07ff/apps/web/components/buttons/KButton.vue').default,Button: require('/tmp/build_acca07ff/apps/web/components/editor/components/Button.vue').default,TipTapButtonDrawer: require('/tmp/build_acca07ff/apps/web/components/editor/components/tiptap/drawer/TipTapButtonDrawer.vue').default})
