import { render, staticRenderFns } from "./NavbarPublic.vue?vue&type=template&id=21eeed12&"
import script from "./NavbarPublic.vue?vue&type=script&lang=ts&"
export * from "./NavbarPublic.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchField: require('/tmp/build_acca07ff/apps/web/components/input/SearchField.vue').default,DropdownNavbar: require('/tmp/build_acca07ff/apps/web/components/navbar/DropdownNavbar.vue').default,KButton: require('/tmp/build_acca07ff/apps/web/components/buttons/KButton.vue').default,NewNavbar: require('/tmp/build_acca07ff/apps/web/components/navbar/NewNavbar.vue').default})
