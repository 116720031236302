import { render, staticRenderFns } from "./SubscribeDrawer.vue?vue&type=template&id=24b9712a&scoped=true&"
import script from "./SubscribeDrawer.vue?vue&type=script&lang=ts&"
export * from "./SubscribeDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./SubscribeDrawer.vue?vue&type=style&index=0&id=24b9712a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b9712a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KButton: require('/tmp/build_acca07ff/apps/web/components/buttons/KButton.vue').default,SubscribeForm: require('/tmp/build_acca07ff/apps/web/components/form/SubscribeForm.vue').default})
