import { render, staticRenderFns } from "./onboarding.vue?vue&type=template&id=350fdeb0&scoped=true&"
import script from "./onboarding.vue?vue&type=script&lang=ts&"
export * from "./onboarding.vue?vue&type=script&lang=ts&"
import style0 from "./onboarding.vue?vue&type=style&index=0&id=350fdeb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "350fdeb0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarRegister: require('/tmp/build_acca07ff/apps/web/components/navbar/NavbarRegister.vue').default,KButton: require('/tmp/build_acca07ff/apps/web/components/buttons/KButton.vue').default,Navigation: require('/tmp/build_acca07ff/apps/web/components/onboarding/Navigation.vue').default})
